import React from 'react';
import Video from './components/Video';
import Qr from './components/Qr'
import ImageDisplay from './components/Image';


 function RenderBar(){

    const queryParameters = new URLSearchParams(window.location.search)
    const qrUrl = queryParameters.get("url")
    const imgUrl = queryParameters.get("img")
    var videoUrl = queryParameters.get("link")

    const url = window.__RUNTIME_CONFIG__.REACT_VIDEO_URL
    if(url.startsWith("force:")){
        console.log("Calling REACT_VIDEO_URL it ok:",url);
        const cleanedUrl = url.substring(6);
        videoUrl = cleanedUrl
    }
    else{
        console.log("IGNORING REACT_VIDEO_URL force default:",url);
    }

    return (
        <div className=" vh-100 py-1 container-fluid" >
            <div className=" row  h-100 px-1 pt-1" >
                <Video
                    videoData = {{ link: videoUrl }}
                />
                {/* <Qr
                    qrData = {{ url: qrUrl }}
                /> */}
                 <ImageDisplay
                    imageData = {{ url: imgUrl }}
                />
            </div>
        </div>
    );
}

function Bar() {

    return <RenderBar/>
}
  
export default Bar;