import React from 'react'

const ImageDisplay = ({ imageData }) => {
    // const qrUrl = window.__RUNTIME_CONFIG__.REACT_QR_URL
    const imageUrl = imageData.url
    console.log("imageUrl URL:",imageUrl);
    // const defaultImageUrl ='https://toolgoinc.com/web/image/5968-9112a3d9/downloadQrs2.png'
    const defaultImageUrl ='https://file.stg.termasys.com/dtuimg.png'
    const validImageUrl = imageUrl ? imageUrl : defaultImageUrl;
    console.log("validImageUrl URL:",validImageUrl);


    return (
        // <div className="col mx-1 px-0  h-100 w-100" style={{ overflow: 'hidden' }}>
        //     {/* <img src={imageUrl} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} /> */}
        //     <img src={imageUrl} alt="Image" style={styles.image} />
        // </div>
        <div className="col mx-1 px-0 h-100 w-100" style={styles.container}>
            <img src={validImageUrl} alt="Image" style={styles.image} />
        </div>
    )
}

const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // or any height you need
      width: '100%', // or any width you need
    //   background: 'orange',
      overflow: 'hidden',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    //   objectFit: 'fill',
      flexShrink: 0,
    },
};

export default ImageDisplay