import React from 'react'
import ReactPlayer from 'react-player'

function Video({videoData}){

    // const videoUrl = window.__RUNTIME_CONFIG__.REACT_VIDEO_URL
    const videoUrl = videoData.link
    console.log("Video URL:",videoUrl);
    // return (
    //     <div className="col px-0 mx-1 h-100 w-100 bg-info" style={{ overflow: 'hidden' }} >
    //         <ReactPlayer
    //             // className='react-player'
    //             width="100%"
    //             height="100%"
    //             url={videoUrl}
    //             playing={true}
    //             loop={true}
    //             // controls={true}
    //         />
    //     </div>
    // )

    // return (
    //     <div className="col mx-1 px-0  h-100 w-100" style={{ overflow: 'hidden' }}>
    //         <ReactPlayer
    //             width="100%"
    //             height="100%"
    //             url="https://youtu.be/xCXJ0mX3NUc?si=vMJsAUiZkyyxDlBC"
    //             playing={true}
    //             loop={true}
    //             muted
    //         />
    //     </div>
    // )

    return (
        <div className="col mx-1 px-0  h-100 w-100" style={{ overflow: 'hidden' }}>
            <ReactPlayer
                width="100%"
                height="100%"
                url={videoUrl}
                playing={true}
                loop={true}
                muted
            />
        </div>
    )

    
}

export default Video

