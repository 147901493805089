import React from 'react'
// import ReactDOM from "react-dom";
// import QRCode from "react-qr-code";
// import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

function Qr({qrData}){
    // const qrUrl = window.__RUNTIME_CONFIG__.REACT_QR_URL
    const qrUrl = qrData.url
    console.log("QR URL:",qrUrl);

    return (
        <div className="col mx-1 px-0  h-100 w-100" style={{ overflow: 'hidden' }}>
           <QRCodeSVG 
            value={qrUrl} 
            width="100%"
            height="100%"
            />
        </div>
    )
}


export default Qr